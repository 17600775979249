.title{
    padding: 20px 10px;
}

/* .editor-container {
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  } */


  .editor-container {
    position: relative;
    width: 100%;
    height: 500px; /* Default height */
    overflow-y: scroll;
  }
  
  .editor-maximized {
    width: 100%;
    height: 100vh; /* Maximized height */
  }
  
  #toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
  }


  
  .editor-tooltip {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .button-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }


  .ql-toolbar .ql-formats .ql-custom {
    display: inline-block;
  }
  
  .ql-toolbar .ql-formats .ql-cut svg,
  .ql-toolbar .ql-formats .ql-copy svg,
  .ql-toolbar .ql-formats .ql-paste svg {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
  
  /* .ql-horizontal-line {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
  } */


  .ql-toolbar button,
  .ql-toolbar .ql-color,
  .ql-toolbar .ql-background {
    position: relative;
  }
  .ql-toolbar button:hover::after,
  .ql-toolbar .ql-color:hover::after ,
  .ql-toolbar .ql-background:hover::after{
    content: attr(class);
    position: absolute;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .ql-toolbar button.ql-bold:hover:after {
    content: 'Bold';
  }
  
  .ql-toolbar button.ql-italic:hover:after {
    content: 'Italic';
  }
  
  .ql-toolbar button.ql-underline:hover:after {
    content: 'Underline';
  }
  
  .ql-toolbar button.ql-strike:hover:after {
    content: 'Strike';
  }

  .ql-toolbar button.ql-blockquote:hover:after {
    content: 'Blockquote';
  }
  
  .ql-toolbar .ql-color:hover:after {
    content: 'ColourPicker';
  }
  
  .ql-toolbar .ql-background:hover:after {
    content: 'BackgroundColor';
  }

  .ql-toolbar button.ql-list[value="ordered"]:hover:after {
    content: 'OrderedList';
  }
  
  .ql-toolbar button.ql-list[value="bullet"]:hover:after {
    content: 'UnorderedList';
  }
  
  .ql-toolbar button.ql-indent[value="-1"]:hover:after {
    content: 'IncreaseIndent';
  }

  .ql-toolbar button.ql-indent[value="+1"]:hover:after {
    content: 'DecreaseIndent';
  }
  
  .ql-toolbar .ql-link:hover:after {
    content: 'Link';
  }

  .ql-toolbar .ql-unlink:hover:after {
    content: 'UnLink';
  }

  .ql-toolbar .ql-image:hover:after {
    content: 'Image';
  }

  .ql-toolbar .ql-clean:hover:after {
    content: 'Clean';
  }

  .ql-toolbar .ql-cut:hover:after {
    content: 'Cut';
  }

  .ql-toolbar .ql-copy:hover:after {
    content: 'Copy';
  }

  .ql-toolbar .ql-paste:hover:after {
    content: 'Paste';
  }

  .ql-toolbar .ql-pasteplain:hover:after {
    content: 'PastePlainText';
  }

  .ql-toolbar .ql-pasteword:hover:after {
    content: 'PasteFromWord';
  }

  .ql-toolbar .ql-undo:hover:after {
    content: 'Undo';
  }

  .ql-toolbar .ql-redo:hover:after {
    content: 'Redo';
  }
  
  .ql-toolbar .ql-anchor:hover:after {
    content: 'Anchor';
  }

  .ql-toolbar .ql-horizontal-line:hover:after {
    content: 'Horizontal-Line';
  }

  .ql-toolbar .ql-special-character:hover:after {
    content: 'Special Character';
  }

  .ql-toolbar .ql-source:hover:after {
    content: 'Source';
  }

  .ql-toolbar .ql-spell-checker:hover:after {
    content: 'Spell Checker';
  }


  
  
  
  
