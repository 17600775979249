.profile-page {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .profile-header {
    display: flex;
    align-items: center; /* Vertically center the contents */
    justify-content: center; /* Horizontally center the contents */
    flex-direction: column; /* Stack image and info vertically */
    margin-bottom: 20px; /* Space between header and other content */
  }
  
  .profile-info {
    text-align: center; /* Center the text */
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  .user-role {
    margin-bottom: 20px;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* background-color: rgb(214, 75, 75); */
  }
  
  .detail-row p {
    flex: 1; /* Ensures both fields share equal space */
    margin: 0 10px; /* Margin for spacing */
  }
  
  .profile-images {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .profile-image {
    max-width: 100%; /* Ensure images are responsive */
    height: auto; /* Maintain aspect ratio */
    border: 1px solid #ccc; /* Optional: add a border around images */
  }
  
  h3 {
    margin: 10px 0;
  }
  
  @media print {
    .hide-when-printing {
      display: none;
    }
  }
  