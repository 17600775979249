.file-input-group {
    display: flex;
    align-items: center;
  }
  
  .file-input-wrapper {
    display: flex;
    align-items: center;
  }
  
  .image-preview {
    margin-left: 10px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }