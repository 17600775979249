

.table-wrapper {
    width: 100%;
    overflow: auto;
  }
  
  .dataTable {
    white-space: nowrap;
    width: 100%;
    table-layout: auto;
  }
  
    .button-group{
      display: flex;
      justify-content: space-around;
    }
  
  