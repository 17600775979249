.event-banner-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .event-banner-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .banner-upload-input {
    margin-bottom: 20px;
  }
  
  .event-banner-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .banner-item {
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .banner-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s;
  }
  
  /* .banner-image:hover {
    transform: scale(1.05);
  } */
  
  .event-images-gallery {
    margin-top: 20px;
  }
  
  .event-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
    gap: 20px; /* Space between the images */
  }
  
  .image-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .event-image {
    width: 100%;
    height: 250px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
    transition: transform 0.3s ease-in-out; /* Add a smooth transition for hover effect */
  }
  
  /* .event-image:hover {
    transform: scale(1.05);
  } */
  .image-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .event-image {
    display: block;
    width: 100%; /* Make sure the image fits its container */
    height: auto;
  }
  
  .delete-icon {
    position: absolute;
    top: 10px; /* Adjust positioning to your liking */
    right: 10px; /* Place it at the top right corner of the image */
    background-color: rgba(255, 0, 0, 0.7); /* Semi-transparent red background */
    border: none;
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
  
  .delete-icon:hover {
    background-color: rgba(255, 0, 0, 1); /* Solid red when hovered */
  }
  
  .delete-icon i {
    pointer-events: none; /* Ensure only the button itself is clickable */
  }
  
  
  .edit-icon {
    position: absolute;
    top: 60px; 
    right: 10px; 
    background-color:blue; 
    border: none;
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
  
  .edit-icon:hover {
    background-color:rgb(20, 20, 163); /* Solid red when hovered */
  }
  
  .edit-icon i {
    pointer-events: none; /* Ensure only the button itself is clickable */
  }
  
  