@media (max-width: 768px) {
    .hide-when-printing {
width: 50%;
background-color: green;   
margin-left: -400px;
margin-top: 400px;
    }
   
  }
  
  @media (max-width: 576px) {
    .hide-when-printing {
        background-color: green;   

    }
  }
  