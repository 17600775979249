.btns-grp{
    width: 650px;
    display: flex;
    justify-content: space-around;
    }

.clear-icon{
    position: absolute;
    right: 37px;
    top: 9px;
    width: 20px;
    height: 20px;
    color: red;
}

