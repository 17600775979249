.styled-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #9f9d9d;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    margin-bottom: 10px; 
    /* font-weight: 500; */
}


.styled-input:not([type="file"]):focus,
.styled-input:not([type="file"]):active {
    border-color: #917fe4;
    box-shadow: 0 0 5px rgba(168, 139, 231, 0.607);
    outline: none;
}

.styled-input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
}

.file-field-container {
    position: relative;
}

.file-field-container .styled-input {
    display: block;
    margin-top: 10px;
}

.range-group .styled-input {
    margin-bottom: 10px;
}


.dummy-image{
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-color: #ccc;
}
  







