

/* *{
    background-color: white;
} */

.styled-input {
    width: 90%;
    padding: 10px;
    border: 1px solid #e1e0e0;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    margin-bottom: 10px; 
    /* font-weight: 500; */
    margin: 5px;
}


.styled-input:not([type="file"]):focus,
.styled-input:not([type="file"]):active {
    border-color: #917fe4;
    box-shadow: 0 0 5px rgba(168, 139, 231, 0.607);
    outline: none;
}


.styled-input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
}

.file-field-container {
    position: relative;
}

.file-field-container .styled-input {
    display: block;
    margin-top: 10px;
}

.range-group .styled-input {
    margin-bottom: 10px;
}


.dummy-image{
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-color: #ccc;
}
  
.search-bar {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 15px; /* Adjust the spacing between the search bar and dropdown */
  }
  
  .styled-input {
    padding: 8px 40px 8px 10px; /* Add space for the icon */
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    color: #888;
  }
  






