.image-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 300px;
  height: 200px;
}

.hover-button {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: rgba(177, 175, 175, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
}

.hover-button:hover {
  background-color: rgba(87, 84, 84, 0.9);
}
