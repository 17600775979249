.form-container{
    width: 100%;
    padding: 20px;
    height: auto;
    margin-bottom: 20px;
}
.form-container H6{
 margin-top: 20px;
 margin-bottom: 30px;
}
.save-btn{
    display: flex;
    justify-content: end;
    
}